import React, {useEffect, useState} from 'react';
import {Alert} from 'react-bootstrap';
import Amplify from 'aws-amplify';
import Container from 'react-bootstrap/Container';
import DynamicConfig from "./Config"
import "./App.scss";

const AppConfig = DynamicConfig.Config

Amplify.configure({
    API: {
        endpoints: [
            {
                name: "awstcoapi",
                endpoint: AppConfig.APIDomain
            }
        ]
    }
});

function App() {

    useEffect(() => {
        if ('fonts' in document) {
            Promise.all([
                document.fonts.load('700 1em AmazonEmber'),
                document.fonts.load('italic 1em AmazonEmber'),
                document.fonts.load('italic 1em AmazonEmber'),
                document.fonts.load('italic 700 1em AmazonEmber')
            ]).then(_ => {
                document.documentElement.classList.add('fonts-loaded')
            });
        }
    }, [])

    const DecommissionedMessage = () => {
        const [secondsLeft, setSecondsLeft] = useState(15);

        useEffect(() => {
            if (secondsLeft > 0) {
                const timer = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
                return () => clearTimeout(timer);
            } else {
                window.location.href = 'https://calculator.aws/#/createCalculator/FSxForNetAppOntap';
            }
        }, [secondsLeft]);

        return (
            <Container className="mt-5">
                <Alert variant="warning">
                    <h4>The FSx for NetApp ONTAP Pricing Calculator has been decommissioned.</h4>
                    <p>
                        For the most accurate pricing estimates, please use the AWS Pricing Calculator.
                        You will be automatically redirected to{' '}
                        <Alert.Link href="https://calculator.aws/#/createCalculator/FSxForNetAppOntap">
                            https://calculator.aws/#/createCalculator/FSxForNetAppOntap
                        </Alert.Link>{' '}
                        in {secondsLeft} seconds.
                    </p>
                </Alert>
            </Container>
        );
    };

    return (
        <div className="apply-font">
            <DecommissionedMessage/>
        </div>
    );
}

export default App;
